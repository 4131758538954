import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FaqLayout from "../../components/faq-layout"
import locale from "../../locale/en"
import SignageContent from "../../components/faq-content/signage"

const { faq } = locale

const Signage = () => (
  <Layout>
    <SEO title={faq.signage} />
    <FaqLayout title={faq.signage}>
      <SignageContent />
    </FaqLayout>
  </Layout>
)

export default Signage
